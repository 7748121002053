import { PlanType } from '@dtx-company/true-common/src/types/planType'

export interface ProUpgradeModalContainerState {
  onPaymentSuccess: () => void
  handleClose: () => void
  planTypeUpgradingTo: PlanType
}

export enum ProUpgradeSourceIds {
  CODE_MANAGEMENT_MOBILE_FAB_CREATE_CODE = 'code-management-mobile-fab-create-code',
  PAGES_CREATE_PAGE = 'pages-create-page',
  FLOWPAGE_CHANGE_FONT = 'flowpage-change-font',
  FLOWPAGE_STYLE_FONT = 'flowpage-style-font',
  FLOWPAGE_CRM_INTEGRATIONS = 'flowpage-crm-integrations',
  FLOWPAGE_CRM_CUSTOM_FIELD = 'flowpage_crm_custom_field',
  FLOWPAGE_CRM_CUSTOM_TERMS = 'flowpage_crm_custom_terms',
  FLOWPAGE_DUPLICATE_PAGE = 'flowpage-duplicate',
  NAV_STORAGE_SECTION = 'nav-storage-section',
  NAV_MOBILE_MORE_MENU = 'nav-mobile-more-menu',
  PLATFORM_FEATURE_LANDING_PAGE = 'platform-feature-landing-page',
  FLOWPAGE_PRO_REMOVE_WHITELABEL = 'flowpage-pro-remove-whitelabel',
  UNIFIED_ASSET_MANAGEMENT_DUPLICATE_CODE = 'unified-asset-management-duplicate-code',
  UNIFIED_ASSET_MANAGEMENT_DUPLICATE_PAGE = 'unified-asset-management-duplicate-page',
  UNIFIED_ASSET_MANAGEMENT_RESTORE_ASSETS = 'unified-asset-management-restore-assets'
}

export enum ProPlusUpgradeSourceIds {
  FLOWPAGE_CREATE_TEMPLATE_MENU = 'flowpage-create-template-menu',
  FLOWCODE_REMOVE_WHITELABEL = 'flowcode-pro-plus-remove-whitelabel',
  FLOWPAGE_SETTINGS_CREATE_TEMPLATE = 'flowpage-settings-create-template'
}
