export enum LockModalProPlusVariants {
  FP_PRO_PLUS_ZAPIER_CRM = 'fpProPlusZapierCRM',
  FC_PRO_PLUS_CODE_LIMIT = 'fcProPlusCodeLimit',
  FP_PRO_PLUS_PAGE_LIMIT = 'fpProPlusPageLimit',
  ANALYTICS_PDF_DOWNLOAD = 'analyticsPDFDownload',
  FP_LINK_LEVEL_STYLING = 'fpLinkLevelStyling',
  FC_PRINT_STORE_DISCOUNT_PRO_TO_PROPLUS_UPSELL = 'fcPrintStoreDiscountProToProPLusUpsell',
  FP_PRO_PLUS_CREATE_TEMPLATE = 'fpProPlusCreateTemplate',
  CORE_PRO_PLUS_DOMAINS = 'corePropPlusDomains',
  PRO_PLUS_FLOWTEAMS = 'proPlusFlowteams',
  FC_WHITELABEL = 'fcProPlusWhitelabel',
  FC_FP_PRO_PLUS_NAV_GET_MORE_STORAGE = 'fcFpProPlusNavGetMoreStorage'
}

export enum LockModalProVariants {
  FP_PRO_HEX_COLORS = 'fpHexColors',
  FP_PRO_FONTS = 'fpFonts',
  FP_PRO_MAILCHIMP_CRM = 'fpProMailchimpCRM',
  FP_PRO_CRM_DISPLAY_TYPE = 'fpProCRMDisplayType',
  FP_STYLED_FONTS = 'fpProStyledFonts',
  FP_PRO_PAGE_LEVEL_LINK_COLORS = 'fpProPageLevelLinkColors',
  FC_PRO_CODE_LIMIT = 'fcProCodeLimit',
  FP_PRO_PAGE_LIMIT = 'fpProPageLimit',
  ANALYTICS_TOP_CODES_CHART = 'analyticsTopCodesChart',
  ANALYTICS_TOP_PAGES_CHART = 'analyticsTopPagesChart',
  ANALYTICS_TOP_TAGS_CHART = 'analyticsTopTagsChart',
  ANALYTICS_TOP_DESTINATIONS_CHART = 'analyticsTopDestinationsChart',
  ANALYTICS_TRAFFIC_CHARTS = 'analyticsTrafficCharts',
  ANALYTICS_TOP_LINKS_CHART = 'analyticsTopLinksChart',
  ANALYTICS_CSV_DOWNLOAD = 'analyticsCSVDownload',
  FC_FP_NAV_GET_MORE_STORAGE = 'fcFpNavGetMoreStorage',
  FP_PRO_WHITELABEL = 'fpProPlusWhitelabel',
  UNIFIED_TEMPLATES_LIMIT = 'unifiedTemplatesLimit'
}

export type LockModalVariants = LockModalProVariants | LockModalProPlusVariants
export const LockModalVariants = { ...LockModalProVariants, ...LockModalProPlusVariants }
