import { DemoModalVariants } from '@dtx-company/true-common/src/types/demoModal'
import { LockModalVariants } from '@dtx-company/inter-app/src/constants/lockModal'
import { ModalTypes, setModalOpen } from '@dtx-company/inter-app/src/redux/slices/modal'
import { PlanType } from '@dtx-company/true-common/src/types/planType'
import { ProUpgradeModalContainerState } from './ProUpgradeModal.types'
import { SyntheticEvent, useCallback, useEffect, useMemo } from 'react'
import { closeLinkEditDrawer } from '@dtx-company/inter-app/src/redux/slices/linkEditor/linkEditorSlice'
import { sendErrorNotification } from '@dtx-company/inter-app/src/utils/notifications'
import { setDemoModalVariant } from '@dtx-company/inter-app/src/redux/slices/demoModalSlice'
import { setLockModalVariant } from '@dtx-company/inter-app/src/redux/slices/lockModalSlice'
import {
  setPaymentSuccessStatus,
  setSourceId,
  setSuccessActionFired
} from '@dtx-company/inter-app/src/redux/slices/proUpgradeModalSlice'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useDispatch } from 'react-redux'
import { useModal } from '@dtx-company/inter-app/src/redux/slices/utils/modal'
import { useProUpgradeModalState } from '@app/common/src/hooks/useProUpgradeModalState'
import { useRouter } from 'next/router'

export interface UseOpenProUpgradeModalArgs {
  openProModalCondition: boolean
  wouldExceedPageLimit?: boolean
  pageLimit?: number
  action?: ({
    isPaymentSuccessful,
    clickEvent
  }: {
    isPaymentSuccessful: boolean
    clickEvent?: Event | SyntheticEvent
  }) => void
  /** This property used to to indicate what content (copy and graphic) to display
   * in the pro upgrade modal. Today `sourceId` seems to be unused for newer lock modals.
   * Marking as optional before potentially removing this prop in the future */
  sourceId?: string
  lockModalVariant?: LockModalVariants
  demoModalVariant?: DemoModalVariants
  onClose?: () => void
  customHeader?: string
  trackingCall?: () => void
  upgradeLink?: string
}

export interface UseProUpgradeModalState {
  handleProtectedActionClick: () => void
}

export function useOpenProUpgradeModal(args: UseOpenProUpgradeModalArgs): UseProUpgradeModalState {
  const { isAuthChecked } = useAuthState()
  const dispatch = useDispatch()
  const router = useRouter()
  const { modalOpen: proModalOpen } = useModal(ModalTypes.PRO_UPGRADE_MODAL)
  const { modalOpen: lockModalOpen } = useModal(ModalTypes.LOCK_MODAL)
  const modalOpen = args.lockModalVariant ? lockModalOpen : proModalOpen

  const {
    isPaymentSuccessful,
    sourceId: openModalSourceId,
    hasSuccessActionFired
  } = useProUpgradeModalState()

  const handleProtectedActionClick = useCallback(
    (clickEvent?: Event | SyntheticEvent<Element, Event>) => {
      if (!isAuthChecked) return
      args.trackingCall?.()
      if (args.openProModalCondition && !isPaymentSuccessful) {
        // optionally override modal behavior to redirect to a different route
        if (args.upgradeLink) {
          router.push(args.upgradeLink)
        } else if (args.demoModalVariant) {
          dispatch(setDemoModalVariant(args.demoModalVariant))
        } else if (args.lockModalVariant) {
          dispatch(setLockModalVariant(args.lockModalVariant))
        } else {
          dispatch(
            setModalOpen({
              type: ModalTypes.PRO_UPGRADE_MODAL,
              ...(args.onClose && { props: { onClose: args.onClose } }),
              ...(args.customHeader && { props: { customHeader: args.customHeader } })
            })
          )
        }
        if (args.sourceId) dispatch(setSourceId(args.sourceId))
        dispatch(closeLinkEditDrawer({}))
      } else if (args.wouldExceedPageLimit && args.pageLimit) {
        sendErrorNotification(`You are limited to ${args.pageLimit} Flowpages`)
      } else {
        args.action?.({ isPaymentSuccessful, clickEvent })
      }
    },
    [isAuthChecked, dispatch, args, isPaymentSuccessful, router]
  )

  useEffect(() => {
    if (
      isAuthChecked &&
      !modalOpen &&
      isPaymentSuccessful &&
      openModalSourceId === args.sourceId &&
      !hasSuccessActionFired
    ) {
      args.action?.({ isPaymentSuccessful })
      dispatch(setSuccessActionFired())
    }
  }, [
    isAuthChecked,
    modalOpen,
    isPaymentSuccessful,
    args,
    dispatch,
    openModalSourceId,
    hasSuccessActionFired
  ])

  return {
    handleProtectedActionClick
  }
}

export function useProUpgradeModalContainerState({
  onClose
}: {
  onClose: () => void
}): ProUpgradeModalContainerState {
  const { hasOperation, refreshJwt } = useAuthState()
  const dispatch = useDispatch()

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const planTypeUpgradingTo = useMemo(() => {
    if (hasOperation('view:pro_plus_upsell_modal')) return PlanType.PRO_PLUS_ORG
    else if (hasOperation('view:pro_upsell_or_upgrade')) return PlanType.PRO
    else return PlanType.PRO
  }, [hasOperation])

  return {
    onPaymentSuccess: async () => {
      handleClose()
      await refreshJwt()
      dispatch(setPaymentSuccessStatus())
    },
    handleClose,
    planTypeUpgradingTo
  }
}
