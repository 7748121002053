import { Box, Tooltip, TooltipProps } from '@dtx-company/design-system/src'
import {
  BoxComponentProps,
  BoxProps,
  CursorType,
  Tooltip as ProUpsellToolTip,
  TooltipProps as ProUpsellTooltipProps
} from '@dtx-company/shared-components/src'
import { DemoModalVariants } from '@dtx-company/true-common/src/types/demoModal'
import { FC, ReactElement, ReactNode, useState } from 'react'
import { LockModalVariants } from '@dtx-company/inter-app/src/constants/lockModal'
import { ProTag } from './ProTag'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { fireAnalyticsEvent } from '@dtx-company/inter-app/src/event-tracking/helpers/fireAnalyticsEvent'
import { useIsMobile } from '@dtx-company/true-common/src/hooks/useIsMobile'
import { useOpenProUpgradeModal } from '../ProUpradeModal/ProUpgradeModal.hooks'
import { useScreenSizeBreakpoints } from '../../../../common/src/hooks/useScreenSizeBreakpoints'
import OutsideClickHandler from 'react-outside-click-handler'
import styled from 'styled-components'

export interface ProFeatureWrapperProps {
  tooltipId: string
  blurOnGated?: boolean
  /** This property used to to indicate what content (copy and graphic) to display
   * in the pro upgrade modal. Today `sourceId` seems to be unused for newer lock modals.
   * Marking as optional before potentially removing this prop in the future */
  sourceId?: string
  showTooltip?: boolean
  gateFeatureAndHideTooltip?: boolean
  upgradeCallback?: () => void
  content?: ReactNode
  children: ReactElement
  vertical?: boolean
  minimalIcon?: boolean
  flexDirection?: BoxProps['flexDirection']
  alignItems?: BoxProps['alignItems']
  trackingCall?: () => void
  lockModalVariant?: LockModalVariants
  demoModalVariant?: DemoModalVariants
  isProPlus?: boolean
  cursor?: CursorType
  tooltipLayout?: TooltipProps['placement']
  iconWidth?: string
  tagCopy?: string
  fullWidth?: boolean
}

export const ProFeatureWrapper: FC<ProFeatureWrapperProps> = ({
  tooltipId,
  blurOnGated = false,
  sourceId,
  showTooltip,
  gateFeatureAndHideTooltip,
  upgradeCallback,
  trackingCall,
  content,
  children,
  vertical,
  minimalIcon,
  flexDirection,
  cursor,
  alignItems = 'baseline',
  lockModalVariant, // This prop (if provided) toggles open the new <LockModal /> instead of the <ProUpsellModal />. The variant controls which graphic/copy to display.
  demoModalVariant,
  isProPlus,
  tooltipLayout = 'right',
  iconWidth,
  fullWidth,
  tagCopy
}) => {
  const innerProps = {
    sourceId,
    upgradeCallback,
    trackingCall,
    vertical,
    minimalIcon,
    children,
    flexDirection,
    alignItems,
    blurOnGated,
    lockModalVariant,
    demoModalVariant,
    isProPlus,
    cursor,
    iconWidth,
    fullWidth,
    tagCopy,
    showTooltip,
    content,
    tooltipId,
    tooltipLayout
  }
  if (gateFeatureAndHideTooltip || showTooltip) {
    return <InnerComponent {...innerProps} />
  } else {
    return children
  }
}

const InnerComponent: FC<
  Pick<
    ProFeatureWrapperProps,
    | 'upgradeCallback'
    | 'sourceId'
    | 'vertical'
    | 'minimalIcon'
    | 'children'
    | 'flexDirection'
    | 'alignItems'
    | 'blurOnGated'
    | 'trackingCall'
    | 'lockModalVariant'
    | 'isProPlus'
    | 'cursor'
    | 'iconWidth'
    | 'demoModalVariant'
    | 'fullWidth'
    | 'showTooltip'
    | 'content'
    | 'tooltipId'
    | 'tooltipLayout'
  > & { tagCopy?: string }
> = ({
  upgradeCallback,
  trackingCall,
  sourceId,
  vertical,
  children,
  minimalIcon,
  flexDirection,
  alignItems,
  blurOnGated,
  lockModalVariant,
  demoModalVariant,
  isProPlus,
  cursor,
  iconWidth,
  fullWidth,
  tagCopy,
  showTooltip,
  content,
  tooltipId,
  tooltipLayout
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { handleProtectedActionClick } = useOpenProUpgradeModal({
    openProModalCondition: true,
    action: upgradeCallback,
    sourceId,
    lockModalVariant,
    demoModalVariant,
    trackingCall
  })
  const { isMobile } = useScreenSizeBreakpoints()
  return (
    <Box
      data-testid={'pfw-inner-component'}
      sx={{
        display: 'flex',
        alignItems: vertical ? 'flex-start' : alignItems,
        flexDirection: vertical ? 'column-reverse' : flexDirection
      }}
      onClick={() => handleProtectedActionClick()}
      // wrapping InnerComponent with Tooltip would cause it to be misaligned
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Box
        data-testid={'pfw-children-wrapper'}
        sx={{
          display: 'flex',
          alignItems: vertical ? 'flex-start' : 'baseline',
          flexDirection: vertical ? 'column-reverse' : 'unset',
          opacity: blurOnGated ? '.5' : '1',
          my: 'auto',
          width: fullWidth ? '100%' : 'auto'
        }}
      >
        {children}
      </Box>
      {vertical && <Spacer mt="12px" />}
      {showTooltip ? (
        <Tooltip
          id={tooltipId}
          title={content}
          placement={isMobile ? 'bottom' : tooltipLayout}
          open={isOpen}
          arrow
          onOpen={() => {
            fireAnalyticsEvent(`ProFeatureWrapper_tooltip_${tooltipId}_open`)
            setIsOpen(true)
          }}
          onClose={() => setIsOpen(false)}
          data-testid={'pfw-tooltip'}
        >
          {/* an immediate element is necessary for the tooltip to function */}
          <span>
            <ProTag
              minimalIcon={minimalIcon}
              isProPlus={isProPlus}
              cursor={cursor}
              iconWidth={iconWidth}
              tagCopy={tagCopy}
            />
          </span>
        </Tooltip>
      ) : (
        <ProTag
          minimalIcon={minimalIcon}
          isProPlus={isProPlus}
          cursor={cursor}
          iconWidth={iconWidth}
          tagCopy={tagCopy}
        />
      )}
    </Box>
  )
}

export interface ProUpsellOverlayProps {
  id: string
  label: string
  content: string
  proTagProps?: BoxComponentProps
  tooltipProps?: Partial<ProUpsellTooltipProps>
  shouldOpenUpsellOnContentClick?: boolean
  trackingCallback?: () => void
  upgradeLink?: string
  tagCopy?: string
  lockModalVariant?: LockModalVariants
  children?: ReactNode
}

const GatedContainer = styled.div`
  width: 100%;
  filter: blur(3px);
  pointer-events: none;
`

// TODO: Consolidate this below logic with the above ProFeatureWrapper such that the Tooltip is bound to the Pro tag at all times - SMEC
export const ProUpsellOverlay: FC<ProUpsellOverlayProps> = ({
  id,
  label,
  content,
  proTagProps,
  tooltipProps,
  shouldOpenUpsellOnContentClick,
  trackingCallback,
  children,
  upgradeLink,
  tagCopy,
  lockModalVariant
}) => {
  const isMobile = useIsMobile()
  const [isTooltipForcedOpen, setIsTooltipForcedOpen] = useState(false)
  const { handleProtectedActionClick } = useOpenProUpgradeModal({
    openProModalCondition: true,
    sourceId: id,
    upgradeLink,
    lockModalVariant
  })

  const handleInteraction = (): void => {
    if (isMobile && !isTooltipForcedOpen) {
      setIsTooltipForcedOpen(true)
    } else {
      trackingCallback?.()
      setIsTooltipForcedOpen(false)
      handleProtectedActionClick()
    }
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setIsTooltipForcedOpen(false)}>
      <Box
        onClick={() => {
          if (shouldOpenUpsellOnContentClick) return handleInteraction()
          isMobile && setIsTooltipForcedOpen(!isTooltipForcedOpen)
        }}
        onMouseEnter={() => !isMobile && setIsTooltipForcedOpen(true)}
        onMouseLeave={() => !isMobile && setIsTooltipForcedOpen(false)}
        sx={{
          display: 'flex',
          position: 'relative',
          cursor: shouldOpenUpsellOnContentClick ? 'pointer' : 'default'
        }}
        data-testid="pro-feature-wrapper"
      >
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            ml: { xs: '10px', md: '17px' },
            mt: { xs: '13px', md: '21px' },
            zIndex: 1
          }}
          {...proTagProps}
        >
          <ProUpsellToolTip
            forceOpen={isTooltipForcedOpen}
            id={id}
            label={label}
            content={content}
            layout="right"
            maxWidth="unset"
            width={['260px', '325px']}
            minHeight="unset"
            hideButton
            additionalOffset={5}
            ignoreClickOutside
            {...tooltipProps}
          >
            <ProTag onClick={handleInteraction} tagCopy={tagCopy} />
          </ProUpsellToolTip>
        </Box>
        <GatedContainer>{children}</GatedContainer>
      </Box>
    </OutsideClickHandler>
  )
}
