import { Box, Chip, theme } from '@dtx-company/design-system/src'
import { CursorType } from '@dtx-company/shared-components/src'
import { FC } from 'react'
import { Icon } from '@dtx-company/shared-components/src/components/atoms/Icon/index'
import { lockIconBlackBackground } from '../../constants/assets'
import LockIcon from '@mui/icons-material/Lock'

interface ProTagProps {
  minimalIcon?: boolean
  onClick?: () => void
  isProPlus?: boolean
  cursor?: CursorType
  iconWidth?: string
  tagCopy?: string
}

export const ProTag: FC<ProTagProps> = ({
  minimalIcon,
  onClick,
  isProPlus = false,
  cursor = 'auto',
  iconWidth = '100%',
  tagCopy
}) => {
  const cursorType = onClick ? 'pointer' : cursor
  const defaultCopy = isProPlus ? 'PRO PLUS' : 'PRO'
  const copy = tagCopy ?? defaultCopy

  return minimalIcon ? (
    <Box
      data-testid={'pro-feature-tag-icon'}
      onClick={onClick}
      role={onClick ? 'button' : 'tooltip'}
      sx={{
        justifyContent: 'center',
        width: iconWidth,
        cursor: cursorType,
        my: 'auto',
        ml: 2
      }}
    >
      <Icon src={lockIconBlackBackground} alt="lock icon" />
    </Box>
  ) : (
    <Chip
      startIcon={LockIcon}
      size={'small'}
      label={copy}
      onClick={onClick}
      data-testid={'pro-feature-tag-text'}
      sx={{
        ml: 2,
        height: theme.spacing(6),
        '&': { backgroundColor: theme.palette.primary.main, color: 'white' },
        '&:hover': { backgroundColor: theme.palette.primary.dark, color: 'white' },
        '.MuiChip-icon': { color: 'inherit' },
        cursor: cursorType
      }}
    />
  )
}
